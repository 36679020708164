@import "@styles/design-system.scss";

//Default Navbar styles starts
.defaultNav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  padding: 0 48px;
  background-color: $black;
  z-index: 999;
  @media screen and (max-width: $tablet_width) {
    padding: 0 24px;
  }
  @media screen and (max-width: $mobile_width) {
    padding: 0 16px;
  }

  // Desktop Nav styles
  .desktopNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;

    .exploreArrowIcon {
      font-size: 10px;
      margin: 10px 0 0 5px;
    }
    .navLhs {
      display: flex;
      align-items: center;
      .brandLogo {
        margin-top: 8px;
      }
      .openDropdown {
        color: $white !important;
      }
      .exploreDropdownBtn {
        margin: 4px 0 0 32px;
        font-family: $open-sans;
        font-weight: 600;
        font-size: $text-14;
        line-height: 22px;
        color: $white-text-secondary;
        transition: all 0.2s linear;
        cursor: pointer;
        .dropdownIcon {
          font-size: $solid-small;
          margin-left: 4px;
        }
        &:hover {
          color: $white;
        }
      }
      .dropdownMenu {
        display: flex;
        background: transparent;
        padding: 0;
        font-family: $open-sans;
        font-weight: 400;
        font-size: $text-14;
        line-height: 22px;
        color: $white-text-secondary;
        border: none;
        .menuLeft {
          background-color: $grey-darker;
          border: none;
          font-family: $open-sans;
          padding-top: 15px;
          width: 300px;
          .menuItem {
            margin: 0 0 10px 0;
            align-items: center;
            background: transparent;
            .courseLink {
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              .categoryName {
                font-size: $text-14;
                line-height: 22px;
                color: $white-text-secondary;
                margin-bottom: 0;
              }
              .rightChevron {
                display: flex;
                justify-content: flex-end;
                color: $white-text-secondary;
              }
            }
            .activeCategory {
              .categoryName {
                color: $yellow-regular;
              }
              .rightChevron {
                color: $yellow-regular;
              }
            }
          }
        }
        .menuRight {
          background-color: $grey-darker;
          border: none;
          width: 238px;
          border-left: 0.5px solid rgba(148, 151, 155, 0.15);
          display: block;
          padding: 25px 0;
          font-family: $open-sans;
          .listHeader {
            font-size: $text-12;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.5);
            margin: 0 0 20px 16px;
          }
          .menuItem {
            margin: 0 0 14px 0;
            display: flex;
            align-items: center;
            .courseLink {
              font-size: $text-14;
              line-height: 22px;
              color: $white-text-secondary;
            }
          }
          .viewAllLink {
            font-size: $text-14;
            line-height: 22px;
            color: $yellow-dark;
            margin-left: 16px;
            &.hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .navRhs {
      display: flex;
      align-items: center;
      .activeNavLink {
        color: $white !important;
      }
      .navLink {
        font-size: $text-14;
        line-height: 22px;
        color: $white-text-secondary;
        margin-right: 32px;
        transition: all 0.2s linear;
        &:hover {
          color: $white;
        }
      }
      .notificationItem {
        margin-right: 32px;
        .notificationBtn {
          width: 32px;
          height: 32px;
          padding: 0;
          background-color: transparent;
          border: none;
          border-radius: 4px;
          transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          &:hover {
            background-color: $grey-darker;
          }
          &:focus {
            background-color: $grey-darker;
          }
          :global {
            .ant-badge {
              color: $white;
              .ant-badge-count {
                background: $error-clr;
                box-shadow: 0 0 0 1px $grey-dark;
              }
            }
          }
        }
      }
    }
  }

  // Mobile Nav styles
  .mobileNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .menuBtn {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  .profileItem {
    .profileButton {
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: 4px;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      &:hover {
        background-color: $grey-darker;
      }
      &:focus {
        background-color: $grey-darker;
      }
    }
  }
}

//Explore dropdown styles
.exploreDropdown {
  width: 298px;
  display: flex;
  :global {
    .ant-dropdown-menu {
      padding: 8px 0;
      background-color: $grey-dark;
      border: 1px solid $grey-regular;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      .ant-dropdown-menu-item {
        padding: 8px 24px;
        font-family: $open-sans;
        font-size: $text-16;
        line-height: 24px;
        color: $white;
        white-space: normal;
        margin-bottom: 8px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        &:hover {
          background-color: $grey-regular;
        }
      }
    }
  }
}

//Notification dropdown styles
.notificationDropdown {
  width: 480px;
  height: 520px;
  :global {
    .ant-menu {
      max-height: 475px;
      padding: 0;
      background: $grey-dark;
      border: 1px solid $grey-regular;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px $black;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        width: 1px;
      }
      .ant-menu-item {
        height: auto;
        line-height: initial;
        margin: 0;
        padding: 0;
        white-space: normal;
        &:hover {
          background-color: $grey-regular;
        }
      }
      .ant-menu-item-selected {
        background-color: $grey-regular;
      }
    }
  }
  .notificationDropdownHeader {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    background-color: $grey-dark;
    box-shadow: inset 0px -1px 0px rgba(148, 151, 155, 0.15);
    z-index: 998;
    .title {
      font-family: $lato;
      font-weight: 900;
      font-size: $text-22;
      line-height: 29px;
      color: $white;
      margin: 0;
    }
    .notificationMenuBtn {
      width: 32px;
      height: 32px;
      padding: 0;
      color: $white;
      background-color: transparent;
      border: none;
      border-radius: 4px;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      &:hover {
        background-color: $grey-darker;
      }
      &:focus {
        background-color: $grey-darker;
      }
    }
    :global {
      .ant-popover-disabled-compatible-wrapper {
        pointer-events: none;
        &:hover {
          background-color: transparent;
        }
        &:focus {
          background-color: transparent;
        }
        .ant-btn[disabled] {
          color: $white-text-disabled;
          background: transparent;
          border: none;
        }
      }
    }
  }
  .emptyMessageWrapper {
    padding: 80px 0;
    .emptyNotification {
      :global {
        .ant-empty-image {
          height: 60px;
        }
      }
    }
  }
  .notificationDropdownItem {
    position: relative;
    display: grid;
    grid-template-columns: 32px 1fr 10px;
    grid-column-gap: 8px;
    padding: 16px;
    cursor: pointer;
    .notificationProfileAvatar {
      font-weight: 600;
      background-color: $grey-darker;
      :global {
        .ant-avatar-string {
          font-size: 14px;
        }
      }
    }
    .itemInfo {
      font-family: $open-sans;
      font-size: $text-14;
      .messageText {
        line-height: 22px;
        color: $white;
        margin-bottom: 4px;
      }
      .timeText {
        line-height: 22px;
        color: $white-text-disabled;
      }
    }
    .unreadIndicator {
      position: absolute;
      top: 16px;
      right: 16px;
      :global {
        .ant-badge {
          .ant-badge-status-dot {
            width: 10px;
            height: 10px;
          }
          .ant-badge-status-processing {
            background-color: $informative;
          }
        }
      }
    }
  }
}

//Notification Menu styles
.notificationMenu {
  padding: 0;
  z-index: 1050;
  :global {
    .ant-popover-content {
      width: 207px;
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner {
        background: $grey-dark;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        .ant-popover-inner-content {
          padding: 0;
        }
      }
    }
  }
  .notificationMenuOption {
    display: block;
    padding: 16px 24px;
    font-family: $open-sans;
    font-size: $text-16;
    line-height: 24px;
    color: $white;
    transition: all 0.3s;
    &:hover {
      background-color: $grey-regular;
    }
  }
}

//Profile Popover styles
.profilePopup {
  top: 42px !important;
  :global {
    .ant-popover-content {
      width: 320px;
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner {
        background: $grey-dark;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        .ant-popover-inner-content {
          padding: 0;
        }
      }
    }
  }
}

//Profile Popup inner styles
.headerContent {
  padding: 24px;
  box-shadow: inset 0px -1px 0px rgba(148, 151, 155, 0.15);
}

.bodyContent {
  padding: 16px 0;
  a {
    display: block;
    margin-bottom: 8px;
    padding: 8px 24px;
    font-family: $open-sans;
    font-size: $text-16;
    line-height: 24px;
    color: $white;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    &:hover {
      background: $grey-regular;
    }
  }
  .notificationLink {
    display: none;
    @media screen and (max-width: $tablet_width) {
      display: block;
    }
    :global {
      .ant-badge {
        color: $white;
        margin-right: 21px;
        .ant-badge-count {
          background: $error-clr;
          box-shadow: 0 0 0 1px $grey-dark;
        }
      }
    }
  }
}
//Default Navbar styles ends

//Course Navbar styles starts
.courseNavbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 84px;
  padding: 16px 24px;
  background-color: $black;
  z-index: 999;
  @media screen and (max-width: $desktop_small_width) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 140px;
  }
  @media screen and (max-width: $tablet_width) {
    padding: 16px 24px;
  }
  @media screen and (max-width: $mobile_width) {
    padding: 16px;
    height: 200px;
  }
  .lhs {
    display: inline-flex;
    align-items: center;
    @media screen and (max-width: $desktop_small_width) {
      margin-bottom: 28px;
    }
    @media screen and (max-width: $mobile_width) {
      margin-bottom: 24px;
    }
    .verticalDivider {
      display: inline-block;
      height: 16px;
      margin: 0 16px;
      border: 1px solid $grey-regular;
    }
    .courseTitle {
      font-family: $lato;
      font-weight: 900;
      font-size: $text-22;
      line-height: 29px;
      color: $white;
      margin: 0;
    }
    .titleImg {
      height: 22px;
      margin-left: 8px;
    }
  }
  .rhs {
    @media screen and (max-width: $tablet_width) {
      width: 100%;
    }
  }
}
//Course Navbar styles ends

//Auth Navbar styles starts
.authNavbar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  padding: 0 48px;
  background-color: $black;
  z-index: 999;
}

//Auth Navbar styles starts
.neutralNavbar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  padding: 0 48px;
  background: $grey-darker;
  box-shadow: inset 0px -1px 0px rgba(148, 151, 155, 0.15);
  z-index: 999;
}

//Skill course Navbar styles starts
.skillCourseNavbar {
  @media screen and (max-width: $mobile_width) {
    height: 120px;
  }
}

.jobCourseNavbar {
  @media screen and (max-width: $mobile_width) {
    height: 120px;
  }
}
